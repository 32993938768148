.turtle--availability {

    .availability-list {
        display: flex;
        justify-content: flex-start;

        padding: $spacing-lg $turtle-side-padding;
    }

    .availability-item {
        margin: 0 $spacing-md;

        .availability-item__chart {
            position: relative;
        }

        svg {
            width: 11rem;
            height: 11rem;
        }

        .availability-item__number {
            @extend .numeric;

            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            color: #25C752;
            font-size: $font-size-title;
            font-weight: $font-weight-semi-bold;
        }

        .availability-item__capacity {
            color: $text-color-medium;
        }

        .availability-item__label {
            display: block;
            margin: $spacing-sm 0;

            text-align: center;
            font-size: $font-size-content;
            font-weight: $font-weight-semi-bold;
        }
    }

}
