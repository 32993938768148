$footer-x-padding: 2vw;
$footer-y-padding: 2vh;


.footer {
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);

    position: relative;
    padding: $footer-y-padding $footer-x-padding;

    height: $footer-height;
    min-height: $footer-height;

    z-index: 8;

    background: var(--primary-color);
    * {
        color: var(--text-color) !important;
    }

    &.footer--inverted {
        background: var(--text-color);
        * {
            color: var(--primary-color) !important;
        }
    }

    .footer__turtle-grid {
        height: $footer-height - $footer-y-padding - $footer-y-padding;

        position: absolute;
        left: $footer-y-padding;
        right: $footer-y-padding;
        top: $footer-y-padding;

        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: $footer-height - $footer-y-padding - $footer-y-padding;
        grid-gap: 0;

        justify-content: stretch;
        align-content: stretch;

        .turtle {
            background: transparent;
        }
    }

    .turtle-container {
        border: none;
    }
}
