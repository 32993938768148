ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style-type: none;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

* {
    box-sizing: border-box;
}
