.splash {
    @extend .centered;

    width: 100vw;
    height: 100vh;

    background: $brand-color;
    color: #fff;

    flex-direction: column;

    .splash__text {
        margin: $spacing-md 0 $spacing-lg 0;
        font-size: $font-size-content;
        font-weight: $font-weight-semi-bold;

        animation: loading-animation;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
}
