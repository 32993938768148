@keyframes loading-animation {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
