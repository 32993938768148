.turtle--image {
    // p-absolute necessary for not breaking out of Grid item (strange bug)
    img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        display: block;
        width: 100%;
        height: 100%;
    }
}
