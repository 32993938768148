.turtle--text {
    display: flex;
    justify-content: stretch;
    align-items: center;

    padding: 0 $spacing-lg;
    font-weight: bold;

    font-size: $font-size-lg;

    p {
        display: block;
        flex-grow: 1;
        white-space: pre-wrap;
        color: var(--primary-color);
    }
}

.footer {
    .turtle--text {
        font-size: $font-size-title;
    }
}
