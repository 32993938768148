.turtle-error {
    margin: $spacing-sm;
    padding: $turtle-side-padding $spacing-sm;

    color: $danger-color;
    font-weight: $font-weight-semi-bold;

    background: $bg-alternate;
    border-radius: 6px;

    text-align: center;
}
