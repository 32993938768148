.turtle-container {
    position: relative;
    overflow: hidden;

    padding: 4px;
}

.turtle-grid {
    opacity: 1;

    &:not(.turtle-grid--active) {
        opacity: 0;
    }
}

.turtle {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    transition: opacity 0.2s ease-in-out;

    overflow: hidden;
    background: #fff;

    &:not(.turtle--active) {
        opacity: 0;
    }
}

@import 'header/header';
@import 'error/error';
@import 'clock/clock';
@import 'image/image';
@import 'video/video';
@import 'embed/embed';
@import 'availability/availability';
@import 'news/news';
@import 'signage/signage';
@import 'map/map';
@import 'weather/weather';
@import 'text/text';
@import 'schedule/schedule';
@import 'reservations/reservations';
