.turtle--news {

    .news-blocks {
        width: 100%;
        padding: $turtle-side-padding;

        .news-block {
            display: flex;
            width: 100%;

            padding: $spacing-sm $spacing-xs;
            background: $bg-alternate;

            border-radius: 0.25rem;

            & + .news-block {
                margin-top: $spacing-md;
            }

            .news-block__image {
                width: 25%;
                align-self: flex-start;

                img {
                    width: 100%;
                    height: auto;
                }

                &.news-block__image--placeholder {
                    height: 140px;

                    background: rgba(#000, 0.05);
                }
            }

            .news-block__content {
                width: 75%;
                padding-left: $spacing-md;

                .news-block__meta {
                    margin-top: $spacing-xs;
                    display: flex;
                    align-items: center;
                    color: $text-color-light;

                    .news-block__time {
                        display: flex;
                        align-items: center;
                        svg {
                            width: 0.8rem;
                            height: 0.8rem;
                            margin-right: 0.2rem;

                            path {
                                fill: $text-color-light;
                            }
                        }

                        margin-left: $spacing-lg;
                    }
                }

                .news-block__title {
                    margin-top: $spacing-sm;
                    font-size: $font-size-medium;
                }

                .news-block__description {
                    margin-top: $spacing-md;
                    font-size: 1.1rem;
                    line-height: 1.3;
                    max-width: 600px;
                }

            }
        }

    }

}
