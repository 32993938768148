

.error {

    &.error--connection {
        display: flex;
        align-items: center;
        justify-content: space-between;

        z-index: 10;
        position: fixed;
        right: 1vh;
        bottom: 1vh + $footer-height;

        padding: $spacing-sm $spacing-lg $spacing-sm $spacing-md;
        background: rgba(#000, 0.8);
        color: #fff;
        line-height: 1;
        border-radius: 10px;

        .error__content {
            flex-grow: 1;
            margin-left: $spacing-md;
        }

        .error__title {
            margin: 0;
            font-size: $font-size-content;
            font-weight: $font-weight-semi-bold;
        }

        .error__description {
            margin: $spacing-xs / 3 0 0 0;

            opacity: 0.6;
            font-size: $font-size-sm;

            animation: loading-animation;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
    }

}

.error-screen {
    @extend .centered;

    width: 100vw;
    height: 100vh;

    background: $brand-color;
    color: #fff;

    flex-direction: column;

    .error-screen__message {
        margin-top: $spacing-md;

        background: white;
        border-radius: 6px;
        padding: $spacing-sm $spacing-md;

        color: $danger-color;
        font-size: $font-size-base;
        font-weight: $font-weight-semi-bold;
    }
}
