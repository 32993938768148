.clock {
    @extend .numeric;
    @extend .centered;

    position: fixed;
    top: 0;
    right: 0;
    height: $turtle-header-height;

    font-size: $font-size-lg;
    font-weight: $font-weight-bold;

    color: var(--text-color);
    background: var(--primary-color);

    padding: 0 $spacing-lg;
}
