.turtle--schedule {

    > li:first-child {
        margin-top: 0.2rem;
    }

    li:nth-child(even) {
        background: $bg-alternate;
    }

    .turtle-header {
        margin-bottom: 0.3rem;
    }

    > ul {
        position: absolute;
        top: $turtle-header-height;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .schedule-item {
        padding: $spacing-xs $turtle-side-padding;

        display: flex;
        justify-content: space-between;
        align-items: center;

        > span {
            line-height: 1.2;
        }

        .schedule-item__badges {
            display: flex;
            align-items: center;

            li {
                @extend .numeric;
                @extend .centered;

                text-align: center;
                font-size: $font-size-content;
                font-weight: $font-weight-semi-bold;
                color: #fff;
                padding: $spacing-xs / 2;
                min-width: $turtle-header-height - 1.4rem;

                min-height: 2.2rem;
            }

            svg {
                width: 74% !important;
                height: 74% !important;
            }

            li:first-child {
                border-bottom-left-radius: 4px;
                border-top-left-radius: 4px;
            }
            li:last-child {
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;
            }
        }

        .schedule-item__label {
            flex-grow: 1;
            margin: 0 $spacing-sm;

            font-size: $font-size-content;
            font-weight: $font-weight-semi-bold;
            line-height: 1;
        }

        .schedule-item__time {
            width: $turtle-time-col-width;
            min-width: $turtle-time-col-width;

            font-size: $font-size-content;
            text-align: center;
            @extend .numeric;
        }

        .schedule-item__delay {
            width: $turtle-info-col-width;
            min-width: $turtle-info-col-width;

            text-align: center;
            font-size: $font-size-content;
            font-weight: $font-weight-semi-bold;
            color: $delay-color;
        }
    }
}
