.flex {
    display: flex;
}
.block {
    display: block;
}
.align-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}
