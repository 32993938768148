/*
 * Colors
 */
$brand-color: #2259A7;
$bg-alternate: #f6f6f6;

$danger-color: #FD0909;
$delay-color: $danger-color;

$text-color: #000;
$text-color-light: #999999;
$text-color-medium: #6E6E6E;

$border-color: #e1e1e1;

/*
 * Dimensions
 */
$footer-height: 10vh;

$turtle-header-height: 4.2rem;
$turtle-side-padding: 1vw;
$turtle-time-col-width: 14%;
$turtle-info-col-width: 12%;

/*
 * Typography
 */
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875;
$font-size-medium: $font-size-base * 1.5;
$font-size-lg: $font-size-base * 2;
$font-size-xl: $font-size-base * 4;

$font-size-title: $font-size-base * 1.75;
$font-size-content: $font-size-base * 1.3125;

$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

/*
 * Spacing
 */
$spacing-xs: 0.5rem;
$spacing-sm: 0.75rem;
$spacing-md: 1rem;
$spacing-lg: 1.5rem;
$spacing-xl: 2rem;
$spacing-xxl: 4rem;



