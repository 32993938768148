.turtle--reservations {
    display: flex;

    .reservation__current {
        display: flex;
        flex-direction: column;
        width: 65%;

        .reservation-content {
            flex-grow: 1;

            display: flex;
            flex-direction: column;
            justify-content: center;

            margin: 0 auto;
            width: 80%;

            .reservation-content__img {
                max-width: 50%;
                height: auto;

                margin-bottom: $spacing-xxl;
            }

            .reservation-content__time {
                @extend .numeric;
                margin: 0;
                font-size: $font-size-title;

                span {
                    padding: 0 $spacing-xs;
                    color: $text-color-light;
                }
            }

            .reservation-content__title {
                font-size: $font-size-xl;
                font-weight: $font-weight-bold;

                margin: 0;
            }

            .reservation-content__announce {
                @extend .numeric;
                font-size: $font-size-title;
            }
        }
    }

    .reservation__schedule {
        width: 35%;
        background: $bg-alternate;

        overflow: hidden;
    }

    .reservation-empty-schedule {
        font-size: $font-size-medium;
        padding: $spacing-xs $turtle-side-padding;
        color: $text-color-light;
    }

    .reservation-schedule {
        margin-top: 0.3rem;
    }

    .reservation-item {
        padding: $spacing-md $turtle-side-padding;

        display: flex;

        > span {
            line-height: 1.2;
        }


        .reservation-item__time,
        .reservation-item__label {
            font-size: $font-size-medium;
        }

        .reservation-item__time {
            min-width: 8rem;
            color: $text-color-medium;
            @extend .numeric;

        }

        .reservation-item__label {
            margin-left: $spacing-md;
            font-weight: $font-weight-semi-bold;
        }

        & + .reservation-item {
            border-top: 1px solid #eee;
        }
    }
}
