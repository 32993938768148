.turtle--signage {

    .signage-list {
        width: 100%;

        padding: $spacing-xs $turtle-side-padding;
    }

    .signage-item {
        display: flex;
        justify-content: flex-start;
        padding: $spacing-lg $turtle-side-padding;

        .signage-item__label {
            @extend .numeric;

            width: 20%;
            height: 5rem;

            display: flex;
            justify-content: center;
            align-items: center;

            text-align: center;
            font-size: 2.6rem;
            font-weight: $font-weight-semi-bold;
        }

        .signage-item__arrows {
            position: relative;

            width: 10%;

            min-width: 5rem;
            height: 5rem;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .signage-item__entities {
            flex-grow: 1;
            margin-top: $spacing-md;
        }

        & + .signage-item {
            border-top: 1px dashed rgba(#000, 0.1);
        }
    }

    .entity {
        display: flex;
        align-items: center;

        .entity__logo {
            width: 20%;
            max-width: 8vw;
            height: auto;
        }

        .entity__name {
            width: 70%;
            margin-left: 10%;

            font-size: $font-size-lg;
            font-weight: $font-weight-semi-bold;
        }

        & + .entity {
            margin-top: $spacing-lg;
        }

    }

    .arrow {
        position: absolute;

        svg {
            height: 2.9rem;
            width: 2.9rem;
        }

        svg path {
            fill: #ccc !important;
        }

        &.arrow--right {
            svg {
                transform: rotate(180deg);
            }
        }
        &.arrow--bottom {
            svg {
                transform: rotate(270deg);
            }
        }
        &.arrow--top {
            svg {
                transform: rotate(90deg);
            }
        }

        &.arrow--multi {
            &.arrow--left {
                padding-right: 2.4rem;
            }
            &.arrow--right {
                padding-left: 2.4rem;
            }
            &.arrow--bottom {
                padding-top: 2.4rem;
            }
            &.arrow--top {
                padding-bottom: 2.4rem;
            }
        }

    }

}
