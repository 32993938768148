.turtle--iframe {
    // p-absolute necessary for not breaking out of Grid item (strange bug)
    iframe {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        width: 100%;
        height: 100%;
        border: none;
        outline: none;
    }
}
