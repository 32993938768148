@font-face {
    font-family: 'Source Sans Pro';
    src: url('../../assets/fonts/sourcesanspro-italic-webfont.woff2') format('woff2'),
    url('../../assets/fonts/sourcesanspro-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../../assets/fonts/sourcesanspro-regular-webfont.woff2') format('woff2'),
    url('../../assets/fonts/sourcesanspro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../../assets/fonts/sourcesanspro-semibold-webfont.woff2') format('woff2'),
    url('../../assets/fonts/sourcesanspro-semibold-webfont.woff') format('woff');
    font-weight: $font-weight-semi-bold;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../../assets/fonts/sourcesanspro-bold-webfont.woff2') format('woff2'),
    url('../../assets/fonts/sourcesanspro-bold-webfont.woff') format('woff');
    font-weight: $font-weight-bold;
    font-style: normal;
}


body,
html {
    font-family: 'Source Sans Pro', sans-serif;
}

body {
    background-color: white; // best practice
    color: $text-color;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
}

html {
    font-size: 12px;

    @media(min-width: 1200px) {
        font-size: 16px;
    }

    @media(min-width: 1800px) {
        font-size: 18px;
    }
}

h1, h2, h3, h4, h5 {
    margin: 0;
}

.h1 {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    line-height: 1.2;
}

.h2 {
    font-size: $font-size-title;
    font-weight: $font-weight-bold;
}

.numeric {
    font-variant-numeric: tabular-nums;
}

.subtitle {
    font-size: $font-size-content;
    font-weight: $font-weight-semi-bold;
}
