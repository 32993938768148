.app {
    @extend .centered;

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}
