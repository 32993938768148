.turtle-header {
    display: flex;
    align-items: center;

    height: $turtle-header-height;

    padding: 0 $turtle-side-padding;

    position: relative;
    // border-bottom: 1px solid rgba(#000, 0.1);

    box-shadow: 0 2px 6px rgba(#000, 0.1);

    .turtle-header__icon {
        @extend .centered;
        width: $turtle-header-height - 1.5rem;
        min-width: $turtle-header-height - 1.5rem;
        height: $turtle-header-height - 1.5rem;

        border-radius: 50%;

        margin-right: $turtle-side-padding / 1.5;

        svg {
            width: 75%;
            height: 75%;
        }
    }

    .turtle-header__title {
        @extend .h2;
        flex-grow: 1;
    }

    .turtle-header__walking-time {
        @extend .centered;

        width: $turtle-time-col-width;
        min-width: $turtle-time-col-width;

        border: 1px solid rgba(#000, 0.1);
        border-radius: 12px;

        padding: 0.4rem $spacing-xs;

        font-size: $font-size-content;
        color: $text-color-light;

        min-height: 54%;

        span {
            line-height: 0;
        }

        svg {
            fill: #000;
            opacity: 0.8;

            width: 1rem;
            height: 1.4rem;

            margin-right: $spacing-xs;
        }
    }

    .turtle-header__logo {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;

        width: $turtle-info-col-width;
        min-width: $turtle-info-col-width;

        img {
            width: auto;
            height: 60% !important;

            max-width: 3.4rem;
            max-height: 3.4rem;
        }
    }
}
