.dashboard {
    display: flex;
    flex-direction: column;

    width: 100vw;
    height: 100vh;

    transform-origin: center;

    .dashboard__turtles-container {
        position: relative;

        background: $border-color;
        height: 100vh - $footer-height;
        max-height: 100vh - $footer-height;

        flex-grow: 1;
    }

    .dashboard__turtle-grid {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-gap: 2px;

        justify-content: stretch;
        align-content: stretch;
    }

    &.dashboard--rotate-90,
    &.dashboard--rotate-270 {
        height: 100vw;
        width: 100vh;
    }

    &.dashboard--rotate-90 {
        transform: rotate(90deg);
    }

    &.dashboard--rotate-180 {
        transform: rotate(180deg);
    }

    &.dashboard--rotate-270 {
        transform: rotate(270deg);
    }

}
