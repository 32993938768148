.turtle--weather {
    display: flex;
    align-items: center;
    padding: 0 $turtle-side-padding;

    .weather-blocks {
        width: 100%;

        display: flex;
        justify-content: space-between;
    }

    .weather-block {
        width: 32%;

        padding: $spacing-sm $spacing-xs;

        text-align: center;
        background: $bg-alternate;
        border-radius: 0.25rem;

        > * {
            display: block;
        }

        .weather-block__icon {
            margin: 0 auto;
            opacity: 0.4;

            width: 2.8rem;
            height: 2.8rem;
        }

        .weather-block__value {
            font-weight: $font-weight-bold;
            font-size: $font-size-xl;
        }

        .weather-block__label {
            font-weight: $font-weight-semi-bold;
            font-size: $font-size-content;
        }
    }

}
