.turtle--clock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    background: var(--primary-color);
    color: var(--text-color);

    text-align: center;

    .turtle__date {
        @extend .numeric;
        opacity: 80%;
        font-size: $font-size-content;
        text-transform: uppercase;
    }

    .turtle__time {
        @extend .numeric;
        @extend .h1;
        line-height: 0.9;

        text-shadow: 0 2px 2px rgba(#000, 0.05);
    }
}

.footer {
    .turtle--clock {
        background: transparent;

        .turtle__date {
            display: none;
        }

        .turtle__time {
            font-size: $font-size-title;
        }
    }
}
